import tw, { styled } from 'twin.macro';

export const Heading = styled.h1`
  ${tw`md:text-6xl xl:text-7xl font-bold`}
  font-size: 2.6rem;
  line-height: 2.6rem;
`

export const TitleICO = styled.h3`
  ${tw`uppercase pb-5`}
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #00b1ff;
`;

export const Title = styled.h2`
  ${tw`text-3xl font-bold`}
`

export const SubtitleICO = styled.h2`
  ${tw`text-4xl sm:text-5xl font-bold`}
  ${tw`w-full`}
`;

export const Subtitle = styled.h3`
  ${tw`text-2xl font-bold`}
`

export const Description = styled.p`
  ${tw`pt-6`}
  ${tw`xl:text-xl`}
`;

export const DSTitle = styled.h2`
  color: white;
  font-size: 28px;
  font-weight: bold;
  line-height: 150%;
`

export const DSDescription = styled.p`
  ${tw`xl:font-size[20px]`}
  color: white;
  font-size: 18px;
  font-weight: normal;
  line-height: 150%;
`
