import { useIntl } from 'gatsby-plugin-react-intl';
import tw, { styled } from 'twin.macro';

const Container = styled.div`
  ${tw`flex w-full justify-center`}
`;

const List = styled.ul`
  ${tw`flex flex-row py-3 px-3 md:px-5 bg-dark rounded-xl items-center`}
  box-shadow: 0px 40px 80px rgba(0, 21, 89, 0.2);
  backdrop-filter: blur(10px);
`;

const Item = styled.li`
  ${tw`ml-3`}
`;

export const SocialBar = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <Container {...props}>
      <List>
        <li>
          <p tw="text-winter text-sm md:text-base uppercase">
            {formatMessage({ id: 'socialBar.talkToUs' })}
          </p>
        </li>
        <Item>
          <a
            href="https://t.me/joinchat/LmT2MYmtZVxkYmI0"
            rel="noreferrer"
            target="_blank"
            tw="text-white hover:text-gray-400"
            aria-label="link to our medium"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              tw="w-6 h-6 md:(w-5 h-5)"
            >
              <path d="M9.41718 13.1814L9.02018 18.7654C9.58818 18.7654 9.83418 18.5214 10.1292 18.2284L12.7922 15.6834L18.3102 19.7244C19.3222 20.2884 20.0352 19.9914 20.3082 18.7934L23.9302 1.82141L23.9312 1.82041C24.2522 0.324405 23.3902 -0.260595 22.4042 0.106406L1.11418 8.25741C-0.338822 8.82141 -0.316822 9.63141 0.867178 9.99841L6.31018 11.6914L18.9532 3.78041C19.5482 3.38641 20.0892 3.60441 19.6442 3.99841L9.41718 13.1814Z" />
            </svg>
          </a>
        </Item>
        <Item>
          <a
            rel="noreferrer"
            href="https://www.facebook.com/Bidda-104083848603876"
            target="_blank"
            tw="text-white hover:text-gray-400"
            aria-label="link to our facebook"
            id="click-gtag-footer-fb"
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              tw="w-6 h-6 md:(w-5 h-5)"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
        </Item>
        <Item>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/bidda_io/"
            target="_blank"
            tw="text-white hover:text-gray-400"
            aria-label="link to our instagram"
            id="click-gtag-footer-ig"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              tw="w-6 h-6 md:(w-5 h-5)"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
        </Item>
        <Item>
          <a
            href="https://twitter.com/bidda_io"
            target="_blank"
            rel="noreferrer"
            tw="text-white hover:text-gray-400"
            aria-label="link to our twitter"
            id="click-gtag-footer-tw"
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              tw="w-6 h-6 md:(w-5 h-5)"
              viewBox="0 0 24 24"
            >
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a>
        </Item>
        <Item>
          <a
            href="https://www.linkedin.com/company/bidda-io/"
            rel="noreferrer"
            target="_blank"
            tw="text-white hover:text-gray-400"
            aria-label="link to our linkedin"
            id="click-gtag-footer-li"
          >
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              tw="w-6 h-6 md:(w-5 h-5)"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              ></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </Item>
      </List>
    </Container>
  );
};
