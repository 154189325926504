import {
  BidsSVG,
  ChartSVG,
  HandsSVG,
  FlameSVG,
  HammerSVG,
  MinimapSVG,
} from '../src/styles/icons';


export const EARLY_ADOPTERS = [
  {
    name: 'Dorota Chmura-Ligoń',
    avatar: 'user.svg',
    jobTitle: 'earlyAdopters.user1.jobtitle',
    description1: 'earlyAdopters.user1.servicename',
    description2: 'Oracka 18C, Bochnia',
    linkB: '',
    linkDoc:
      'https://www.znanylekarz.pl/dorota-chmura-ligon/stomatolog/bochnia?utm_source=google&utm_medium=gmb&utm_campaign=57024&utm_content=website',
    linkG:
      'https://www.google.com/search?q=lek.+dent.+Dorota+Chmura-Ligo%C5%84%2C+Stomatolog&ei=rRjnYITTCPCGwPAP5aivwAc&oq=lek.+dent.+Dorota+Chmura-Ligo%C5%84%2C+Stomatolog&gs_lcp=Cgdnd3Mtd2l6EAMyAggmOggIABCwAxDNAkoECEEYAVCFpAFYhaQBYM6oAWgDcAB4AIABlQGIAZkCkgEDMC4ymAEAoAECoAEBqgEHZ3dzLXdpesgBAcABAQ&sclient=gws-wiz&ved=0ahUKEwjE3Z-C5NPxAhVwAxAIHWXUC3gQ4dUDCA4&uact=5',
    linkFB: '',
    linkIG: '',
  },
  {
    name: 'Kamila Dębosz',
    avatar: 'camnails.png',
    jobTitle: 'earlyAdopters.user2.jobtitle',
    description1: 'earlyAdopters.user2.servicename',
    description2: 'Świętego Floriana 12, Krzeczów',
    linkB:
      'https://booksy.com/pl-pl/63448_camnails-beauty-studio-urody_salon-kosmetyczny_10189_bochnia',
    linkDoc: '',
    linkG:
      'https://www.google.com/search?q=camnails%26beauty+studio+urody&oq=camnails+bea&aqs=chrome.2.69i57j0i22i30l2j69i60l3.4669j0j7&sourceid=chrome&ie=UTF-8',
    linkFB: 'https://www.facebook.com/CamNailsBeauty-2052872418326662/',
    linkIG: 'https://www.instagram.com/camnails_studio/?utm_source=ig_embed',
  },
  {
    avatar: 'karina.jpg',
    name: 'Karina Majewska',
    jobTitle: 'earlyAdopters.user3.jobtitle',
    description1: 'earlyAdopters.user3.servicename',
    description2: '',
    linkDoc: '',
    linkG: 'https://nauka-spiewu.pl/',
    linkFB: 'https://www.facebook.com/naukaspiewu/',
    linkIG: '',
  },
  {
    avatar: 'barbell.png',
    name: 'Daniel Bibro',
    jobTitle: 'earlyAdopters.user4.jobtitle',
    description1: 'earlyAdopters.user4.servicename',
    description2: 'Krzeczowska 93, Bochnia',
    linkB: '',
    linkDoc: '',
    linkG: 'https://barbell-club.pl/',
    linkFB: 'https://www.facebook.com/Barbell-Club-Bochnia-147107666004181/',
    linkIG: '',
  },
  {
    avatar: 'jaga.jpeg',
    name: 'Ewa Sura',
    jobTitle: 'earlyAdopters.user5.jobtitle',
    description1: 'earlyAdopters.user5.servicename',
    description2: 'Starowiejska 11/101, Katowice',
    linkB: '',
    linkDoc: '',
    linkG: 'https://jagabeauty.com/',
    linkFB: 'https://www.facebook.com/jagabeautycoach/',
    linkIG: 'https://www.instagram.com/jagabeautycoach/',
  },
];

export const JOBS = [
  {
    name: 'hero.main2',
  },
  {
    name: 'hero.main3',
  },
  {
    name: 'hero.main4',
  },
  {
    name: 'hero.main5',
  },
  {
    name: 'hero.main6',
  },
  {
    name: 'hero.main7',
  },
  {
    name: 'hero.main8',
  },
];

export const HOW_ITEMS = [
  {
    title: 'howItWorks.user.step1.title',
    description: 'howItWorks.user.step1.description',
    Icon: MinimapSVG,
  },
  {
    title: 'howItWorks.user.step2.title',
    description: 'howItWorks.user.step2.description',
    Icon: BidsSVG,
    top: 120,
  },
  {
    title: 'howItWorks.user.step3.title',
    description: 'howItWorks.user.step3.description',
    Icon: HammerSVG,
    top: 235,
  },

  {
    title: 'howItWorks.business.step1.title',
    description: 'howItWorks.business.step1.description',
    Icon: FlameSVG,
    top: 235,
  },
  {
    title: 'howItWorks.business.step2.title',
    description: 'howItWorks.business.step2.description',
    Icon: ChartSVG,
    top: 120,
  },
  {
    title: 'howItWorks.business.step3.title',
    description: 'howItWorks.business.step3.description',
    Icon: HandsSVG,
  },
];
export const FAQ_ITEMS = [
  {
    question: 'faq.question1',
    answer: 'faq.answer1',
  },
  {
    question: 'faq.question2',
    answer: 'faq.answer2',
  },
  {
    question: 'faq.question3',
    answer: 'faq.answer3',
  },
  {
    question: 'faq.question4',
    answer: 'faq.answer4',
  },
  {
    question: 'faq.question5',
    answer: 'faq.answer5',
  },
  {
    question: 'faq.question6',
    answer: 'faq.answer6',
  },
  {
    question: 'faq.question7',
    answer: 'faq.answer7',
  },
  {
    question: 'faq.question8',
    answer: 'faq.answer8',
  },
  {
    question: 'faq.question9',
    answer: 'faq.answer9',
  },
];

export const MILESTONES = [
  {
    title: 'roadmap.dates.0.title',
    date: 'roadmap.dates.0.date',
    quarter: '2021 Q1',
    left: true,
  },
  {
    title: 'roadmap.dates.1.title',
    date: 'roadmap.dates.1.date',
    quarter: '2021 Q1',
    left: false,
  },
  {
    title: 'roadmap.dates.2.title',
    date: 'roadmap.dates.2.date',
    quarter: '2021 Q1',
    left: true,
    bg: '#00b1ff',
  },
  {
    title: 'roadmap.dates.3.title',
    date: 'roadmap.dates.3.date',
    quarter: '2021 Q1',
    left: false,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.4.title',
    date: 'roadmap.dates.4.date',
    quarter: '2021 Q2',
    left: true,
  },
  {
    title: 'roadmap.dates.5.title',
    date: 'roadmap.dates.5.date',
    quarter: '2021 Q2',
    left: false,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.6.title',
    date: 'roadmap.dates.6.date',
    quarter: '2021 Q3',
    left: true,
  },
  {
    title: 'roadmap.dates.7.title',
    date: 'roadmap.dates.7.date',
    quarter: '2021 Q3',
    left: false,
    special: true,
    border: '#f30072',
    scrollTo: true,
  },
  {
    title: 'roadmap.dates.8.title',
    date: 'roadmap.dates.8.date',
    quarter: '2021 Q3',
    left: true,
    bg: '#6366f1',
    border: '#6366f1',
    inactive: true,
  },
  {
    title: 'roadmap.dates.9.title',
    date: 'roadmap.dates.9.date',
    quarter: '2021 Q3',
    left: false,
    inactive: true,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.10.title',
    date: 'roadmap.dates.10.date',
    quarter: '2021 Q4',
    left: true,
    special: true,
    border: '#f30072',
    inactive: true,
  },
  {
    title: 'roadmap.dates.11.title',
    date: 'roadmap.dates.11.date',
    quarter: '2021 Q4',
    left: false,
    inactive: true,
    border: '#f30072',
  },
  {
    title: 'roadmap.dates.12.title',
    date: 'roadmap.dates.12.date',
    quarter: '2021 Q4',
    left: true,
    special: true,
    border: '#f30072',
    inactive: true,
  },
  {
    title: 'roadmap.dates.13.title',
    date: 'roadmap.dates.13.date',
    quarter: '2021 Q4',
    left: false,
    border: '#f30072',
    inactive: true,
  },
  {
    title: 'roadmap.dates.14.title',
    date: 'roadmap.dates.14.date',
    quarter: '2022 Q1',
    left: true,
    inactive: true,
    border: '#f30072',
  },
  {
    title: 'roadmap.dates.15.title',
    date: 'roadmap.dates.15.date',
    quarter: '2022 Q2',
    left: false,
    inactive: true,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.16.title',
    date: 'roadmap.dates.16.date',
    quarter: '2022 Q2',
    left: true,
    inactive: true,
    border: '#6366f1',
  },

  {
    title: 'roadmap.dates.17.title',
    date: 'roadmap.dates.17.date',
    quarter: '2022 Q3',
    left: false,
    inactive: true,
  },
  {
    title: 'roadmap.dates.18.title',
    date: 'roadmap.dates.18.date',
    quarter: '2022 Q3',
    left: true,
    inactive: true,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.19.title',
    date: 'roadmap.dates.19.date',
    quarter: '2022 Q3',
    left: false,
    inactive: true,
    border: '#f30072',
  },
  {
    title: 'roadmap.dates.20.title',
    date: 'roadmap.dates.20.date',
    quarter: '2022 Q4',
    left: true,
    inactive: true,
    border: '#6366f1',
  },
  {
    title: 'roadmap.dates.21.title',
    date: 'roadmap.dates.21.date',
    quarter: '2022 Q4',
    left: false,
    inactive: true,
  },
];

export const QUARTERS = [
  {
    title: '2021 Q1',
    items: [
      {
        title: 'roadmap.dates.0.title',
        date: 'roadmap.dates.0.date',
        quarter: '2021 Q1',
        level: 4,
        left: 4,
      },
      {
        title: 'roadmap.dates.1.title',
        date: 'roadmap.dates.1.date',
        quarter: '2021 Q1',
        level: 1,
        left: 280,
      },
    ],
  },
  {
    title: '2021 Q2',
    items: [
      {
        title: 'roadmap.dates.2.title',
        date: 'roadmap.dates.2.date',
        quarter: '2021 Q1',
        level: 3,
        left: 240,
        bg: '#00b1ff',
      },
    ],
  },
  {
    title: '2021 Q3',
    items: [
      {
        title: 'roadmap.dates.3.title',
        date: 'roadmap.dates.3.date',
        quarter: '2021 Q1',
        level: 2,
        left: 260,
        border: '#6366f1',
      },
      {
        title: 'roadmap.dates.4.title',
        date: 'roadmap.dates.4.date',
        quarter: '2021 Q2',
        level: 4,
        left: -85,
      },
      {
        title: 'roadmap.dates.5.title',
        date: 'roadmap.dates.5.date',
        quarter: '2021 Q2',
        level: 2,
        left: 270,
        border: '#6366f1',
      },
    ],
  },
  {
    title: '2021 Q4',
    items: [
      {
        title: 'roadmap.dates.6.title',
        date: 'roadmap.dates.6.date',
        quarter: '2021 Q3',
        level: 4,
        left: 4,
      },
      {
        title: 'roadmap.dates.7.title',
        date: 'roadmap.dates.7.date',
        quarter: '2021 Q3',
        level: 2,
        left: 270,
        special: true,
        border: '#f30072',
      },
    ],
  },
  {
    title: '2022 Q1',
    items: [
      {
        title: 'roadmap.dates.8.title',
        date: 'roadmap.dates.8.date',
        quarter: '2021 Q3',
        level: 3,
        left: 55,
        bg: '#6366f1',
        inactive: true,
        border: '#6366f1',
      },
      {
        title: 'roadmap.dates.9.title',
        date: 'roadmap.dates.9.date',
        quarter: '2021 Q3',
        level: 4,
        left: -75,
        inactive: true,
        border: '#6366f1',
      },
      {
        title: 'roadmap.dates.10.title',
        date: 'roadmap.dates.10.date',
        quarter: '2021 Q4',
        level: 4,
        left: 110,
        special: true,
        border: '#f30072',
        inactive: true,
      },
      {
        title: 'roadmap.dates.11.title',
        date: 'roadmap.dates.11.date',
        quarter: '2021 Q4',
        level: 1,
        left: 150,
        inactive: true,
        border: '#f30072',
      },
    ],
  },
  {
    title: '2022 Q2',
    items: [
      {
        title: 'roadmap.dates.12.title',
        date: 'roadmap.dates.12.date',
        quarter: '2021 Q4',
        level: 2,
        left: 240,
        special: true,
        border: '#f30072',
        inactive: true,
      },
      {
        title: 'roadmap.dates.13.title',
        date: 'roadmap.dates.13.date',
        quarter: '2021 Q4',
        level: 3,
        left: 335,
        border: '#f30072',
        inactive: true,
      },
    ],
  },
  {
    title: '2022 Q3',
    items: [
      {
        title: 'roadmap.dates.14.title',
        date: 'roadmap.dates.14.date',
        quarter: '2022 Q1',
        level: 1,
        left: 155,
        inactive: true,
        border: '#f30072',
      },
      {
        title: 'roadmap.dates.15.title',
        date: 'roadmap.dates.15.date',
        quarter: '2022 Q2',
        level: 2,
        left: 10,
        inactive: true,
        border: '#6366f1',
      },


    ],
  },
  {
    title: '2022 Q4',
    items: [
      {
        title: 'roadmap.dates.16.title',
        date: 'roadmap.dates.16.date',
        quarter: '2022 Q2',
        level: 4,
        left: 45,
        inactive: true,
        border: '#6366f1',
      },


    ],
  },
  {
    title: '2023 Q1',
    items: [
      {
        title: 'roadmap.dates.17.title',
        date: 'roadmap.dates.17.date',
        quarter: '2022 Q3',
        level: 1,
        left: 20,
        inactive: true,
      },
      {
        title: 'roadmap.dates.18.title',
        date: 'roadmap.dates.18.date',
        quarter: '2022 Q3',
        level: 2,
        left: 64,
        inactive: true,
        border: '#6366f1',
      },
    ]
  },
  {
    title: '2023 Q2',
    items: [
      {
        title: 'roadmap.dates.19.title',
        date: 'roadmap.dates.19.date',
        quarter: '2022 Q3',
        level: 3,
        left: 90,
        inactive: true,
        border: '#f30072',
      },
      {
        title: 'roadmap.dates.20.title',
        date: 'roadmap.dates.20.date',
        quarter: '2022 Q4',
        level: 4,
        left: 103,
        inactive: true,
        border: '#6366f1',
      },
    ]
  },
  {
    title: '2023 Q3',
    items: [
      {
        title: 'roadmap.dates.21.title',
        date: 'roadmap.dates.21.date',
        quarter: '2022 Q4',
        level: 2,
        left: 60,
        inactive: true,
      },
    ]
  }

];
