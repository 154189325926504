import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export interface SeoProperties {
  location?: Location;
  title?: string;
  description?: string;
  image?: string;
  article?: string;
}

const { appUrl, saleUrl, portalContactEmail } = {
  saleUrl: 'https://www.bidda.io/',
  appUrl: 'https://app.bidda.io/',
  portalContactEmail: 'hello@bidda.io',
};

export const SEO = ({
  title,
  description,
  image,
  article,
  location,
}: SeoProperties) => {
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${location.pathname}`,
  };
  return (
    <>
      <Helmet title={seo.title}>
        <meta name="description" content={seo.description} />

        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content={seo.image} />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
      <meta
        name="facebook-domain-verification"
        content="lrfhhtulqv3trh0dl66xjfx8zjuvwr"
      />
      <OrganizationGoogleMeta
        domain={siteUrl}
        name={defaultTitle}
        contactEmail={portalContactEmail}
      />
      <BreadcrumbListGoogleMeta appPage={appUrl} salesPage={saleUrl} />
    </>
  );
};

const OrganizationGoogleMeta = ({
  domain,
  name,
  contactEmail,
}: {
  domain: string;
  name: string;
  contactEmail: string;
}) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${domain}",
          "logo": "${domain}/logo.svg",
          "name": "${name}",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "${contactEmail}",
            "contactType": "Customer Support"
          }
        }
      `}
    </script>
  </Helmet>
);
const BreadcrumbListGoogleMeta = ({
  salesPage,
  appPage,
}: {
  salesPage: string;
  appPage: string;
}) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Bidda token sale",
              "item": "${salesPage}"
          },{
              "@type": "ListItem",
              "position": 1,
              "name": "Bidda app",
              "item": "${appPage}"
          }]
        }
      `}
    </script>
  </Helmet>
);

export const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
